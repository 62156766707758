
import { defineComponent } from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import prices from '@/services/prices';
import BaseTable from '@/components/ui/BaseTable.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { ConvertDate } from '@/components/converters/date';

export default defineComponent({
  name: 'RrcPricePage',
  components: {
    BaseIcon,
    BaseTable,
    DropdownEllipsis
  },
  data: () => ({
    formDataFile: null as any,
    archiveData: [] as any,
  }),
  computed: {
    getLinkForDownloadTemplate() {
      return prices.getTemplateRrc()
    },
    headers() {
      return [
        {
          title: 'Имя пользователя',
          content: (item: any) => item.username
        },
        {
          title: 'Дата загрузки',
          content: (item: any) => ConvertDate(item.dateTimeUploaded)
        },
        {
          title: 'Загрузка',
          content: (item: any) => `${process.env.VUE_APP_URL_FILES}${item.link.split('\\').join('/')}`
        },
      ]
    }
  },
  methods: {
    async deleteFile(fileId: any) {
      try {
        await prices.deletePriceRrc(fileId)
        this.archiveData = this.archiveData.filter((archiveData: any) => {
          return archiveData.id !== fileId
        })
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    parseTable(e: any) {
      let file = e.target.files;
      const formData = new FormData();
      formData.append('file', file[0]);
      this.formDataFile = formData
    },
    async sendFile() {
      try {
        await prices.importPriceRrc(this.formDataFile)
        await this.setFiles()
        this.formDataFile = null
        const input = document.querySelector('input[type=file]') as any
        input.value = '';
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    async setFiles() {
      const response = await prices.rrcSearch()
      this.archiveData = response.data
    }
  },
  async mounted() {
    await this.setFiles()
  }
})
