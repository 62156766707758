export enum PageableSort {
  ASC = 'asc',
  DESC = 'desc'
}

export interface Pageable {
  page: number,
  size?: number,
  sort?: PageableSort,
  totalPages?: number,
  type?: string
}

export interface PageableInfoSort {
  sorted: boolean,
  unsorted: boolean,
  empty: boolean
}

export interface PageableInfo {
  sort: PageableInfoSort,
  offset: number,
  pageSize: number,
  pageNumber: number,
  unpaged: boolean,
  paged: boolean,
}

export interface IPageableContent<T> {
  content: Array<T>
  pageable: PageableInfo,
  totalElements: number,
  totalPages: number,
  last: boolean,
  size: number,
  number: number,
  sort: PageableInfoSort,
  numberOfElements: number,
  first: boolean,
  empty: boolean
}
