import {httpClient} from "@/httpClient";
import {IPageableContent, Pageable, PageableSort} from "@/models/PageableModel";
import { IComparisonRequestData, IDataFile } from '@/models/PricesModel';
import axios, {AxiosResponse} from 'axios';
import {store} from "@/store";

async function getPricesForArticles(payload: any, page?: number) {

  // TODO Model of payload:
  //   ArticleSearchRequest {
  //     articleIds (Array[integer], optional),
  //     articles (Array[string], optional),
  //     statuses (Array[string], optional),
  //     categories (Array[string], optional),
  //     saleTypes (Array[string], optional),
  //     startDate (integer, optional),
  //     endDate (integer, optional),
  //     productGroups (Array[integer], optional),
  //     commentArticles (Array[string], optional),
  //     commentApplics (Array[string], optional),
  //     articleReplaces (Array[integer], optional),
  //     complex (Array[string], optional)
  //   }

  const onlyFocusedBrands = store.getters.onlyFocusedBrands
  const response = await httpClient.post(`/prices/analysis/${localStorage.getItem('cid')}?page=${page}&onlyFocus=${onlyFocusedBrands}`, payload);
  return response.data;
}

async function getPricesForMasteringSheet(id: any, page = 0) {
  const onlyFocusedBrands = store.getters.onlyFocusedBrands
  const response = await httpClient.post(`/prices/analysis/${localStorage.getItem('cid')}/mastering-sheet/${id}?page=${page}&onlyFocus=${onlyFocusedBrands}`);
  return response.data;
}

async function exportPricesForArticles(payload: any): Promise<any> {
  const onlyFocusedBrands = store.getters.onlyFocusedBrands
  const response = await httpClient.post(`/prices/analysis/${localStorage.getItem('cid')}/export?onlyFocus=${onlyFocusedBrands}`,
    payload, {
      responseType: 'blob'
    }
  );
  return response.data;
}

async function exportPricesForMasteringSheet(id: number, payload: any): Promise<any> {
  const onlyFocusedBrands = store.getters.onlyFocusedBrands
  const response = await httpClient.post(`/prices/analysis/${localStorage.getItem('cid')}/mastering-sheet/${id}/export?onlyFocus=${onlyFocusedBrands}`,
    payload, {
      responseType: 'blob'
    }
  );
  return response.data;
}

async function getFilesPrices(pageable: Pageable): Promise<IPageableContent<IDataFile>> {
  const {
    page = 0,
    size = 20,
    sort = PageableSort.DESC,
    type = 'MANUFACTURER'
  } = pageable;

  return httpClient.get(`/prices/import/${localStorage.getItem('cid')}/files?page=${page}&size=${size}&sort=datePrice,${sort}&type=${type}`)
    .then((response: AxiosResponse) => response.data)
}

function downloadPrice(id: number) {
  return `${axios.defaults.baseURL}/prices/import/${localStorage.getItem('cid')}/files/download/${id}`
}

function deletePricesByFile(fileId: number | string) {
  return httpClient.delete(`/prices/import/${localStorage.getItem('cid')}/delete/${fileId}`)
}

function getTemplateImport() {
  return `${axios.defaults.baseURL}/prices/import/${localStorage.getItem('cid')}/files/template`
}

async function updatePrice(priceId: number | string, payload: any): Promise<IDataFile> {
  return await httpClient.put(`/prices/import/${localStorage.getItem('cid')}/files/update/${priceId}`, payload).then((response) => response.data)
}

async function pricesComparison(payload: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/comparison`, payload).then((response) => response.data)
}

async function toExcel(requestComparisonData: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/export`, requestComparisonData,
    {
      responseType: 'blob'
    }).then((response) => response.data)
}

async function pricesComparisonExcel(requestComparisonExcelData: {to: number, from: number}) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/comparison`, requestComparisonExcelData,
    {
      responseType: 'blob'
    }).then((response) => response.data)
}

function toExcelGeneralReport() {
  return `${axios.defaults.baseURL}/prices/import/${localStorage.getItem('cid')}/comparison`
}

async function pricesComparisonByBrand(payload: {from: number, to: number}) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/comparison`, payload).then((resposne) => resposne.data)
}

async function pricesComparisonExport(requestComparisonExcelData: {to: number, from: number, reason: string}) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/comparison-export`, requestComparisonExcelData,
    {
      responseType: 'blob'
    }).then((response) => response.data)
}

async function search(payload: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/search`, payload).then(response => response.data)
}

async function searchByPrefix(prefix: string) {
  return httpClient.get(`/prices/import/${localStorage.getItem('cid')}/searchByArticle/?prefix=${prefix}`)
}

function getTemplateRrc() {
  return `${axios.defaults.baseURL}/prices/import/${localStorage.getItem('cid')}/rrc/template`
}

function importPriceRrc(formData: any) {
  return httpClient.post(`/prices/import/${localStorage.getItem('cid')}/rrc`, formData)
}

function rrcSearch() {
  return httpClient.post(`/prices/import/${localStorage.getItem('cid')}/rrc/search?sort=dateTimeUploaded,desc`, {})
}

function deletePriceRrc(fileId: any) {
  return httpClient.delete(`/prices/import/${localStorage.getItem('cid')}/rrc/${fileId}`)
}

export default {
  getPricesForArticles,
  exportPricesForArticles,
  exportPricesForMasteringSheet,
  getPricesForMasteringSheet,
  getFilesPrices,
  downloadPrice,
  getTemplateImport,
  updatePrice,
  pricesComparison,
  toExcel,
  pricesComparisonByBrand,
  toExcelGeneralReport,
  pricesComparisonExcel,
  pricesComparisonExport,
  search,
  searchByPrefix,
  deletePricesByFile,
  getTemplateRrc,
  importPriceRrc,
  rrcSearch,
  deletePriceRrc,
}
